
import React, { useState, useContext, useEffect } from 'react';
import { dvSrv, enableStripe, SessionContext } from '../App';
import axios from 'axios';
import { toast } from "react-toastify"
import ReactJson from '@microlink/react-json-view';

export const BillingInfo = () => {
    const session = useContext(SessionContext)
    const [account, setAccount] = useState()
    const [data, setData] = useState({})
    const [flag, setFlag] = useState(false)

    //const noShow = !enableStripe || session?.data?.Account.AccountType !== "Customer"
    useEffect(() => {
        // if (noShow){
        //    return
        // }
        axios.get(dvSrv + `/api/account/self`, {withCredentials: true})
            .then(({data}) => {
            // console.log(data)
            setAccount(data)
            setData({})
            })
            .catch(error => {if (error.response?.status === 401) {
            setAccount(null)
            setData({})
            session.setData(null)
            } else if (error.response?.status !== 204) {
                // http.StatusNoCentent (204) means there is no Billing found, it's ok
                console.error("Error: ", error)}})
    }, [flag])

    const refresh = () => {
        setFlag(!flag)
        setData({})
    }
    
    const handleValueChange = (field, val) => {
        setData({...data, [field]: val})
    }
    
    const saveBillingInfo = (e) => {
        axios.post(dvSrv + `/api/account/self`, data, {withCredentials: true})
        .then(({data}) => {
            setAccount(data)
            setData({})
            toast.success("Billing info is saved successfully.")
            })
        .catch(error => { 
            console.error("Error: ", error) 
            toast.error(error?.response?.data?.error)
            } )    
    }
/* 
    BillingAddressStreet     string
	BillingAddressCity       string
	BillingAddressState      string
	BillingAddressPostalcode string
	BillingAddressCountry    string
	AccountPayableEmail      string // email to send invoice, online bill?
	InvoiceEmail             string

	PhoneOffice   string
	PhoneFax      string //`gorm:"size:10"`

// type Address struct {
// 	AddressLine       string
// 	AddressLine2      string
// 	AddressLine3      string
// 	City              string
// 	StateProvinceCode string
// 	Postalcode        string
// 	PostalCodeExt     string
// 	CountryCode       string
// }
// 	AccountPayableEmail string // email to send invoice, online bill?
// 	InvoiceEmail        string
// 	PhoneOffice         string
// 	FaxOffice           string //`gorm:"size:10"`
*/
  if (account) {
  return (
    <>     
      <h3 class="mt-3 mb-3">Billing Address and Contact</h3>
        <div class="my-2 row">
            <label for="staticStreet" class="col-sm-2 col-form-label">Address Line 1</label>
            <div class="col-sm-6">
                <input type="text" class="form-control" id="staticStreet" 
                value={Object.keys(data).includes("AddressLine") ? data["AddressLine"] : account?.AddressLine} 
                onChange={(e) => handleValueChange("AddressLine", e.target.value)}/>
            </div>
        </div>
        <div class="my-2 row">
            <label for="staticStreet2" class="col-sm-2 col-form-label">Address Line 2</label>
            <div class="col-sm-6">
                <input type="text" class="form-control" id="staticStreet2" 
                value={Object.keys(data).includes("AddressLine2") ? data["AddressLine2"] : account?.AddressLine2} 
                onChange={(e) => handleValueChange("AddressLine2", e.target.value)}/>
            </div>
        </div>
        <div class="my-2 row">
            <label for="staticStreet3" class="col-sm-2 col-form-label">Address Line 3</label>
            <div class="col-sm-6">
                <input type="text" class="form-control" id="staticStreet3" 
                value={Object.keys(data).includes("AddressLine3") ? data["AddressLine3"] : account?.AddressLine3} 
                onChange={(e) => handleValueChange("AddressLine3", e.target.value)}/>
            </div>
        </div>
        <div class="my-2 row">
            <label for="staticCity" class="col-sm-2 col-form-label">City</label>
            <div class="col-sm-6">
                <input type="text" class="form-control" id="staticCity" 
                value={Object.keys(data).includes("City") ? data["City"] : account?.City} 
                onChange={(e) => handleValueChange("City", e.target.value)}/>
            </div>
        </div>
        <div class="my-2 row">
            <label for="staticState" class="col-sm-2 col-form-label">State</label>
            <div class="col-sm-6">
                <input type="text" class="form-control" id="staticState" 
                value={Object.keys(data).includes("StateProvinceCode") ? data["StateProvinceCode"] : account?.StateProvinceCode} 
                onChange={(e) => handleValueChange("StateProvinceCode", e.target.value)}/>
            </div>
        </div>
        <div class="my-2 row">
            <label for="staticZipCode" class="col-sm-2 col-form-label">Zip Code</label>
            <div class="col-sm-6">
                <input type="text" class="form-control" id="staticZipCode" 
                value={Object.keys(data).includes("Postalcode") ? data["Postalcode"] : account?.Postalcode} 
                onChange={(e) => handleValueChange("Postalcode", e.target.value)}/>
            </div>
        </div>
        <div class="my-2 row">
            <label for="staticZipCodeExt" class="col-sm-2 col-form-label">Zip Code Ext</label>
            <div class="col-sm-6">
                <input type="text" class="form-control" id="staticZipCodeExt" 
                value={Object.keys(data).includes("PostalCodeExt") ? data["PostalCodeExt"] : account?.PostalCodeExt} 
                onChange={(e) => handleValueChange("PostalCodeExt", e.target.value)}/>
            </div>
        </div>
        <div class="my-2 row">
            <label for="staticCountryCode" class="col-sm-2 col-form-label">Country</label>
            <div class="col-sm-6">
                <input type="text" class="form-control" id="staticCountryCode" 
                value={Object.keys(data).includes("CountryCode") ? data["CountryCode"] : account?.CountryCode} 
                onChange={(e) => handleValueChange("CountryCode", e.target.value)}/>
            </div>
        </div>
        <div class="my-2 row">
            <label for="staticPhone" class="col-sm-2 col-form-label">Phone</label>
            <div class="col-sm-6">
                <input type="text" class="form-control" id="staticPhone" 
                value={Object.keys(data).includes("PhoneOffice") ? data["PhoneOffice"] : account?.PhoneOffice} 
                onChange={(e) => handleValueChange("PhoneOffice", e.target.value)}/>
            </div>
        </div>
        <div class="my-2 row">
            <label for="staticFax" class="col-sm-2 col-form-label">Fax</label>
            <div class="col-sm-6">
                <input type="text" class="form-control" id="staticFax" 
                value={Object.keys(data).includes("FaxOffice") ? data["FaxOffice"] : account?.FaxOffice} 
                onChange={(e) => handleValueChange("FaxOffice", e.target.value)}/> 
            </div>
        </div>
        <div class="my-2 row">
            <label for="staticAccountPayableEmail" class="col-sm-2 col-form-label">Account Payable Email</label>
            <div class="col-sm-6">
                <input type="text" class="form-control" id="staticAccountPayableEmail" 
                value={Object.keys(data).includes("AccountPayableEmail") ? data["AccountPayableEmail"] : account?.AccountPayableEmail} 
                onChange={(e) => handleValueChange("AccountPayableEmail", e.target.value)}/>
            </div>
        </div>
        <div class="my-2 row">
            <label for="staticInvoiceEmail" class="col-sm-2 col-form-label">Email to Receive Invoice</label>
            <div class="col-sm-6">
                <input type="text" class="form-control" id="staticInvoiceEmail" 
                value={Object.keys(data).includes("InvoiceEmail") ? data["InvoiceEmail"] : account?.InvoiceEmail} 
                onChange={(e) => handleValueChange("InvoiceEmail", e.target.value)}/>
            </div>
        </div>
        <div class="my-2 row">
            <button class="col-sm-2 btn btn-outline-primary" onClick={saveBillingInfo}>Save</button>
            <button class="col-sm-2 btn btn-outline-secondary mx-2" onClick={refresh}>Reset</button>
        </div>
      <hr />

      {/* <h3 class="mt-1 mb-3">Subscription</h3>
        {billing ? <MySubscriptions /> 
            : 
            <div>
            <SimplePlanInfo />
            <span>Fill in Billing info above to subscribe to the plan</span>
            </div>} 
    
      <hr />*/}
    </>
  )}
}
