import React, { useState, useEffect, useRef, useContext } from 'react';
import axios from 'axios';
import ReactJson from "@microlink/react-json-view"
import { dvSrv, SessionContext } from '../App';
import { CaseReport, ReportStatus } from './Report';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCirclePlus, faFlag } from '@fortawesome/free-solid-svg-icons'
import { Tooltip } from "react-tooltip"

export const CaseReportWithAttempts = ({caseNumber}) => {
  const session = useContext(SessionContext)
  // State to manage tabs
  const defRepTab = { id: 'field_report', label: 'Intubation Report', attempt: 0 , type: 'Field_Report'}
  const [tabs, setTabs] = useState([defRepTab])
  const [activeTab, setActiveTab] = useState('field_report')
  const [savedReports, setSavedReports] = useState([])
  const [flag, setFlag] = useState(false)
  const [numReportChanged, setNumReportChanged] = useState(false)
  
  const handleSaved = (type, att) => {
    setFlag(!flag)
    for( const rep of savedReports) {
      if (type===rep.TemplateType && rep.Attempt === att) return
    }
    setNumReportChanged(true)
  }
  const [status, setStatus] = useState("")
  useEffect(() => {
    axios.get(dvSrv + `/api/report/${caseNumber}`, {withCredentials: true})
    .then(({data}) => {
      setSavedReports(data)
        // console.log("Reports: ", data)
      if (data.length > 0){
          let repTabs = [defRepTab]
          data.sort((a, b) => a.Attempt - b.Attempt).map(rep => {
            if (rep.Attempt > 0){
              repTabs.push({ id: `att_report_${rep.Attempt}`, label: `Attempt ${rep.Attempt}` , attempt: rep.Attempt, type: rep.TemplateType})}
            if (rep.Attempt === 0){
              setStatus(rep.Status)
            }
          })
          if (repTabs.length>tabs.length) setTabs(repTabs)
          setActiveTab(activeTab)
          setNumReportChanged(false)
      } 
    })
    .catch(error => {
      if (error.response && error.response.status === 401) {
          session.setData(null)
      } else {console.error("Error: ", error)}
  })}, [caseNumber, numReportChanged])

  // Function to add a new tab
  const addTab = () => {
    const newTabId = `att_report_${tabs.length}`

    const newTabs = [...tabs]
    newTabs.push( { id: newTabId, label: `Attempt ${tabs.length}`, attempt: tabs.length, type: 'Attempt_Report' })
    // console.log("newTabs: ", newTabs)
    setTabs(newTabs)
    setActiveTab(newTabId)
  }

  // Function to remove a tab
  const removeTab = (tabId) => {
    if (tabId === 'field_report') {
      alert('Cannot remove the Field_Report tab!')
      return
    }
    const remTab = tabs.filter((tab) => tab.id === tabId)
    if (remTab.length !== 1) return

    if (!window.confirm(`Are you sure you want to remove Report for Attempt ${remTab[0].attempt}?`)) return

    try {
      
      axios.delete(dvSrv + `/api/report/${caseNumber}/att/${remTab[0].attempt}`, {withCredentials: true})
      .then(({data}) => {
        const updatedTabs = tabs.filter((tab) => tab.id !== tabId)
        setTabs(updatedTabs)
        setNumReportChanged(true)
        setFlag(!flag)
        if (activeTab === tabId) {
          setActiveTab('field_report')
        }
        // toast.success("Attempt report is removed!")
      })
    }catch(error){
      if (error.response && error.response.status === 401) {
        session.setData(null)
      } else {console.error("Error: ", error)}
    }
  }

  const getFlagColor = () =>{
    // console.log("reports: ", reports)
    if (savedReports.length === 0) return 'red'
    // let frs = savedReports.filter(r=>r.Attempt === 0)
    return status===ReportStatus.Done ? 'green' : 'orange'
  }

  // console.log("activeTab: ", activeTab)
  return (
    <div>
      <h5 class="card-title text-center mb-2"><FontAwesomeIcon icon={faFlag} 
                  color={getFlagColor()}/>&nbsp;&nbsp;Case # {caseNumber}</h5>
      {/* Tab List */}
      <ul className="nav nav-tabs" id="reportsTab" role="tablist">
        {tabs.map((tab) => (
          <li className="nav-item" role="presentation" key={tab.id}>
            <button
              className={`nav-link ${activeTab === tab.id ? 'active' : ''}`}
              id={`${tab.id}-tab`}
              data-bs-toggle="tab"
              data-bs-target={`#${tab.id}`}
              type="button"
              role="tab"
              aria-controls={tab.id}
              aria-selected={activeTab === tab.id}
              onClick={() => setActiveTab(tab.id)}
            >
              {tab.label}
              {tab.id !== 'field_report' && tab.attempt === tabs.length-1 && (
                <span
                  className="ms-2"
                  onClick={(e) => {
                    e.stopPropagation() // Prevent tab switch when clicking "x"
                    removeTab(tab.id)
                  }}
                  style={{ cursor: 'pointer' }}
                >
                  ×
                </span>
              )}
            </button>
          </li>
        ))}
        {/* Add Tab Button */}
        <li className="nav-item">
          <button className="nav-link" id="btn-new-att" onClick={addTab} 
            disabled={tabs.length>savedReports.length || status===ReportStatus.Done}>
            <FontAwesomeIcon icon={faCirclePlus}/>
          </button>
        </li>
      </ul>

      {/* Tab Content */}
      <div className="tab-content" id="reportsTabContent">
        {tabs.map((tab) => (
          <div
            className={`tab-pane fade ${activeTab === tab.id ? 'show active' : ''}`}
            id={tab.id}
            role="tabpanel"
            aria-labelledby={`${tab.id}-tab`}
            key={tab.id}
          >
            {/* <p>Content for {tab.label}</p> */}
            <CaseReport caseNumber={caseNumber} type={tab.type} attempt={tab.attempt} onSaved={handleSaved} update={flag} 
              status={status} setStatus={setStatus}/>
            
          </div>
        ))}
      </div>
      <Tooltip anchorSelect="#btn-new-att" place="top">Add Attempt Report</Tooltip>
    </div>
  )
}
