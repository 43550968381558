import { useContext, useEffect, useState } from "react";
import axios from "axios";
import { dvSrv, SessionContext } from "./App";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArchive, faCheckCircle, faCircleInfo, faCirclePlay, faCloudUpload, faDatabase, faInfo, faInfoCircle, faNoteSticky, faRecycle, faRefresh, faRemove, faTrashCan, faWindowClose  } from "@fortawesome/free-solid-svg-icons";
import { faFileVideo, faFolderOpen } from "@fortawesome/free-regular-svg-icons";
import { GetNodeTeam, GetNodeUsers} from "./model/Team"
import ReactPlayer from "react-player"
import ReactJson from "@microlink/react-json-view";
import { toast } from "react-toastify";
import { Button, Modal } from "react-bootstrap";
import { Tooltip } from "react-tooltip";
import { TimeStringForTimeZone } from "./model/Setting";
import { PaginationTable } from "./model/Pagination";
import { useDevices } from "./hook/DataProvider";
import { GetDeviceSNWithTag } from "./model/Device";
import { GetUserFullname } from "./model/User";

export const MyPreloaded = () => {
  const session = useContext(SessionContext)
  //const [data, setData] = useState([])
  const [preload, setPreload] = useState([])
  const [deleted, setDeleted] = useState([])
  const [flag, setFlag] = useState(false)

  const refresh = () => setFlag(!flag)

  const [teamTree, setTeamTree] = useState()
  const [teams, setTeams] = useState([])
  const [users, setUsers] = useState([]) // i.e., assigned users
  const preloadedUrl = `/api/preload/self/all`
  const paginationUrl = `/api/preload/self`

  useEffect(() => {
    if (teamTree) {
    setUsers(GetNodeUsers(teamTree)) // all assigned users
    setTeams(GetNodeTeam(teamTree))
    }}, [teamTree])

  useEffect(() => {
    axios.get(dvSrv + `/api/team/self`, {withCredentials: true})
    .then(({data}) => setTeamTree(data))
    .catch(error => {
      if (error.response.status === 401) {
        session.setData(null)
      } else { console.error("Error: ", error) }
    })}, [])

  useEffect(() => {
    updatePreload()
  }, [flag])

  const updatePreload =() =>{
    axios.get(dvSrv + preloadedUrl, {withCredentials: true})
    .then(({data}) => {
      //setData(data)
      setPreload(data.filter(video => !video.MarkDelete).sort((a, b) => new Date(b.UpdatedAt) - new Date(a.UpdatedAt)))
      setDeleted(data.filter(video => video.MarkDelete).sort((a, b) => new Date(b.UpdatedAt) - new Date(a.UpdatedAt)))
      // console.log('Preloaded data:', data);
    })
    .catch(error => {
      if (error.response.status === 401) {
        session.setData(null)
      } else { console.error("Error: ", error) }
    })
  }

  const handleSaveAll = (e) => {
    if (preload && preload.length > 0){
      if (window.confirm("This will save files from ALL pages in the preloaded table. Click OK to continue!")){
        document.body.style.cursor = 'wait'
        axios.get(dvSrv + `/api/preload/save`, 
    //    {SaveVideos: data},
        {withCredentials: true})
        .then(({data}) => {
          refresh()
          document.body.style.cursor = 'default'
        })
        .catch(error => { 
          document.body.style.cursor = 'default'
          console.error("Error: ", error) } )      
      }
    }
  }
  const handleDeleteAll = (e) => {
    if (deleted && deleted.length > 0){
      if (window.confirm("Are you sure you want to delete all videos in trash can? If click OK to continue, the videos in trash can will be removed from the database, but the original files will be kept for 10 more days and then deleted permanently!")){
          axios.get(dvSrv + `/api/preload/delete`, 
    //    {SaveVideos: data},
        {withCredentials: true})
        .then(({data}) => refresh())
        .catch(error => { console.error("Error: ", error) } )      
      }
    }
  }

  const addSinglePreload = (uploaded) => {
    //updatePreload()
  }
  const addAllPreload = (uploaded) => {
    updatePreload()
    refresh()
  }

  return (
    <>
    <Uploader addSinglePreload={addSinglePreload} addAllPreload={addAllPreload}/> 
    
    <hr />
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <h2><FontAwesomeIcon icon={faArchive}/> Uploaded</h2>
      <button class="btn btn-lg py-2" id="btn-refresh-files" onClick={refresh}><FontAwesomeIcon icon={faRefresh}/></button>
    </div>
    {/* <h2><FontAwesomeIcon icon={faArchive}/> Uploaded</h2> */}

    <PreloadTableWithPagination apiUrl={paginationUrl} users={users} teams={teams} refresh={refresh} repaging={flag}/>
    <div class="align-items-center">
      <button class="btn btn-outline-primary py-2" 
        onClick={handleSaveAll} disabled={!(preload && preload.length > 0)}>
        <FontAwesomeIcon icon={faDatabase} style={{ marginRight: '5px' }}/> <span class="fw-bold">Save All</span></button>
        <FontAwesomeIcon icon={faCircleInfo} color="#0dcaf0" style={{ marginLeft: '10px' }}/><span class="text-center py-2" style={{ marginLeft: '3px' }}>Files uploaded with case number are saved automatically.</span>
    </div>

    <hr />
    <h3><FontAwesomeIcon icon={faTrashCan}/></h3>
    <VideoPreloadTable videos={deleted} users={users} teams={teams} refresh={refresh}/>
    {session && session.data && session.data.IsRoot && <div>
      <button class="btn btn-outline-primary py-2" 
        onClick={handleDeleteAll} disabled={!(deleted && deleted.length > 0)}>
        <FontAwesomeIcon icon={faRemove} color="red" style={{ marginRight: '5px' }}/> <span class="fw-bold">Delete All</span></button>
    </div>
    }
    <Tooltip anchorSelect="#btn-refresh-files" place="top" arrowColor="#222222">Refresh</Tooltip>
     {/* { <ReactJson src={{preload}} /> } */}
    </>
  )
}

export const UPLOAD_FILES_ONE_BY_ONE = true
// https://codefrontend.com/file-upload-reactjs/
export const Uploader = ({addSinglePreload, addAllPreload}) => {
  const session = useContext(SessionContext)

  const [selectedFileList, setSelectedFileList] = useState()
  const [uploaded, setUploaded] = useState()
  const [data, setData] = useState()

  const [uploading, setUploading] = useState(false)
  const [showCaseModal, setShowCaseModal] = useState(false)

  useEffect(() => {
      if (uploading) {
          document.body.style.cursor = 'wait'  // Set cursor to waiting spinner
      } else {
          document.body.style.cursor = 'default'  // Set cursor back to default
      }
  }, [uploading])
  // const onFileChange = (e) => {
  //   setSelectedFileList(e.target.files)
  //   setUploaded(false)
  // }

  const onFileUpload = (e) => {
    if (e.target.files) {
      const files = [...e.target.files]
      sortAndUpload (files, false)
      e.target.value = null
    }
  }

  // https://stackoverflow.com/questions/57749112/how-to-upload-a-full-folder-with-their-files
  const onFolderUpload = (e) => {
    if (e.target.files) {
      const files = [...e.target.files]
      sortAndUpload (files, true)
      e.target.value = null // To clear the file box 
    }
  }

  const sortAndUpload = (files, useFolder) => {   
    var num = 0
    var size = 0
    files.forEach((file, i) => {
      num += 1
      size += file.size
    })

    if (!window.confirm(`There are total ${num} files (${Math.round(size/1024/1024)} MB) selected to upload.\nProceed?`)) {
      return
    }

    // Prompt the user to enter case number
    const caseNumInput = window.prompt('Please enter a case number. Files uploaded with case number will be saved automatically. Click Cancel to continue without a case number');
    const caseNum = caseNumInput !== null ? caseNumInput.trim() : caseNumInput

    // sort files in ascending order based on their size
    files.sort((a, b) => a.size - b.size);

    if (UPLOAD_FILES_ONE_BY_ONE){
      uploadSingles(files, useFolder, caseNum)
    } else {
      uploadMulti(files, num, size, useFolder, caseNum)
    }

  }

  async function uploadSingles (files, useFolder, caseNumber) {

    setUploaded(false)
    setUploading(true)

    let preloaded = []
    let failed = []
    for (const [i, file] of files.entries()) {
    //files.forEach((file, i) => {
      // We only allow up to 500 MB for one upload
      if (file.size > 524288000 /*419430400*/){
        toast.warning(`The file ${file.name} is ${Math.round(file.size/1024/1024)} MB, which is over 500MB upload limit!`)
        continue
      }
      const formData = new FormData()
      if (caseNumber){
        formData.append("caseNumber", caseNumber)
      } 
       
      formData.append(`file-${i}`, file, file.name)
      if (useFolder){
        formData.append(`filepath-${i}`, file.webkitRelativePath)
      }

      try {
        const response = await axios.post(dvSrv + "/api/preload", formData, {withCredentials: true})
        console.log(response.data)
        addSinglePreload(response.data)
        preloaded.push(response.data)
      } catch (error) {
          console.error("Error uploading "+file.name+", ", error)
          failed.push(file.name)
      }

      // axios.post(dvSrv + "/api/preload", formData, {withCredentials: true})
      // .then(({data}) => {
      //   refresh() // ask video file tabs to refresh
      // })
      // .catch(error => {
      //   console.error("Error: ", error)
      //   if (error.response.status === 401) {
      //     session.setData(null)
      //     setUploaded(true)
      //     setUploading(false)
      //     return
      //   } else { console.error("Error: ", error) }
      // })

    }

    if (failed.length > 0){
      const result = failed.join("\n");
      alert(`The following files failed to upload:\n\n` + result)
    }
    setUploaded(true)
    setUploading(false)
    if (preloaded.length > 0){
      addAllPreload(preloaded)
    }
  }

  const uploadMulti = (files, num, size, useFolder, caseNumber) => {
    
    // We only allow up to 500 MB for one upload
    if (size > 524288000 /*419430400*/){
      alert(`There are total ${num} files (${Math.round(size/1024/1024)} MB) to upload.\nPlease make sure the total size is below 500MB for one upload!`)
      return
    }

    const formData = new FormData()
    if (caseNumber){
      formData.append("caseNumber", caseNumber)
    } 
    // formData.append("device", "A00C383") // TODO
    files.forEach((file, i) => {
      formData.append(`file-${i}`, file, file.name)
      if (useFolder){
        formData.append(`filepath-${i}`, file.webkitRelativePath)
      }
    })
    
    setUploaded(false)

//    if (window.confirm(`There are total ${num} files (${Math.round(size/1024/1024)} MB) to upload.\nProceed?`)) {
      setUploading(true)
      axios.post(dvSrv + "/api/preload", formData, {withCredentials: true})
      .then(({data}) => {
        setUploaded(data.uploaded.length > 0)
        setData(data)
        setUploading(false)
        addAllPreload(data)
      })
      .catch(error => {
        setUploading(false)
        console.error("Error: ", error)
        if (error.response.status === 401) {
          session.setData(null)
        } else { console.error("Error: ", error) }
      })
//    }
  }

  return (
    <>
      <div class="row mb-5">
        <div class="col-6 p-5">
          <label for="formFolderMultiple" class="form-label fs-2 fw-bold"> <FontAwesomeIcon icon={faFolderOpen} /> Upload Folder</label>
          <div class="input-group-lg">
            <input class="form-control" type="file" onChange={onFolderUpload} id="formFolderMultiple" multiple webkitdirectory="" directory=""
              accept="video/*, .mov" />
            {/* {selectedFileList && !uploaded 
            ? <button class="btn btn-info px-5" onClick={onFolderUpload}>Upload your folder</button>
            : uploaded 
              ? <button class="btn btn-success px-5"><FontAwesomeIcon icon={faCheckCircle} /> Uploaded</button>
              : <></>
            } */}
          </div>
        </div>

        <div class="col-6 p-5">
          <label for="formFileMultiple" class="form-label fs-2 fw-bold">
            <FontAwesomeIcon icon={faFileVideo} /> Upload Files

          </label>
          <div class="input-group-lg">
            <input class="form-control" type="file" onChange={onFileUpload} // onChange={onFileChange} 
              id="formFileMultiple" multiple accept="video/*, .mov" />
            {selectedFileList && !uploaded 
            ? <button class="btn btn-info" onClick={onFileUpload}>Upload your files</button>
            : uploaded 
              ? <button class="btn btn-success"><FontAwesomeIcon icon={faCheckCircle} /> Uploaded</button>
              : uploading 
                ? <button class="btn btn-info"><FontAwesomeIcon icon={faCloudUpload} /> Uploading ...</button>
                : <></>
            }
          </div>
        </div>
      </div>
      {/* {uploaded && <CaseAssignModal show={showCaseModal} setShow={setShowCaseModal} assignCase={assignCase} />} */}

      {/* {data && data.duplicates && data.duplicates.length > 0 && 
      <div>
      <label class="form-label fw-bold"> Duplicated Files Uploaded!</label>
      <ul>
        { data.duplicates.map(
           dup => <li><span>{dup}</span></li>
        )}
      </ul>

      </div>} */}

      {/* <MyPreloaded /> */}

      {/* { <ReactJson src={data} /> } */}
    </>
  )
}

const CaseAssignModal = ({show, setShow, assignCase}) => {

  const [caseNumber, setCaseNumber] = useState("")
  const onHide = () => setShow(false)
  const handleAssign = () => {
    setShow(false)
    assignCase(caseNumber)
  }  

  return (
    <Modal show={show} onHide={onHide} centered={true}>
      <Modal.Header closeButton>
        <Modal.Title>Assign Case Number to Files</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div class="input-group mb-3">
          <input type="text" class="form-control" placeholder="Case #" aria-label="Case Number" aria-describedby="basic-addon2" value={caseNumber} onChange={e=>setCaseNumber(e.target.value)}/>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleAssign}>Assign</Button>
        <Button variant="secondary" onClick={onHide}>Skip</Button>
      </Modal.Footer>
    </Modal>  
  )
}
  const _columns = [
    { key: 'source', label: 'Device | Tag', jfield:'Source', sortable: true, searchable: true },
    { key: 'file_name', label: 'File Name', jfield:'FileName', sortable: true, searchable: false  },
    { key: 'file_size', label: 'Size (MB)', jfield:'FileSize', sortable: true, searchable: false  },
    { key: 'duration', label: 'Duration (m:s)', jfield:'Duration', sortable: true, searchable: false  },
    { key: 'create_date', label: 'Create Time', jfield:'CreateDate', sortable: true, searchable: true },
    // { key: 'case_number', label: 'Case #', sortable: true, searchable: true },
    { key: 'provider_id', label: 'Assigned To', jfield:'ProviderID', sortable: true, searchable: true },
    { key: 'created_at', label: 'Upload Time', jfield:'CreatedAt', sortable: true, searchable: true },
    { key: 'team_id', label: 'Team', jfield:'TeamID', sortable: true, searchable: false },
    { key: 'action', label: 'Action', jfield:'NA', sortable: false, searchable: false },
    // Add more columns as needed
  ]

export const PreloadTableWithPagination = ({apiUrl, teams, users, refresh, repaging}) => {
  const devices = useDevices()

  const itemProps = {
    teams: teams,
    users: users,
    devices: devices,
  }
//   if (devices)
  return (
    <PaginationTable apiUrl={apiUrl} columns={_columns} sortbycol='create_date' refresh={refresh} repaging={repaging} ItemComponent={VideoPreloadTab} itemProps={itemProps}/>
  )
}


export const VideoPreloadTable = ({videos, teams, users, refresh}) => {

  if (videos)
  return (
    <table class="table table-hover table-bordered">
    <thead>
    {_columns.map((column) => <th key={column.key}>{column.label}</th> )}
      {/* <th scope="col">#</th> */}
      {/* <th>_columns.</th>
      <th>Filename</th>
      <th>Size (MB)</th>
      <th>Duration (m:s)</th>
      <th>Create Time</th>
      <th>Case #</th>
      <th>Provider</th>
      <th>Upload Time</th>
      <th>Team</th>
      <th></th> */}
      {/* <th>Last Updated</th> */}
    </thead>
    <tbody>
      {videos.map(video => <VideoPreloadTab item={video} teams={teams} users={users} refresh={refresh} />)}
    </tbody>
  </table>
  )
}

export const VideoPreloadTab = ({item, teams, users, devices, refresh}) => {
  const [data, setData] = useState(item)
  const [isPlaying, setIsPlaying] = useState(false);
  const session = useContext(SessionContext)

  useEffect(() => {
    setData(item)
  }, [item])

  const handleFlipDelete = (e) => {
    //if (window.confirm("Are you sure you want to delete this video?")){
      axios.get(dvSrv + `/api/preload/${item.ID}/flipdelete`, {withCredentials: true})
      .then(({data}) => refresh())
      .catch(error => { console.error("Error: ", error) } )      
    //}
  }
  const handleSaveSingle = (e) => {
    //if (window.confirm("Are you sure you want to delete this video?")){
      axios.get(dvSrv + `/api/preload/${item.ID}/save`, {withCredentials: true})
      .then(({data}) => refresh())
      .catch(error => { console.error("Error: ", error) } )      
    //}
  }
  
  const [buttonIcon, setButtonIcon] = useState(faCirclePlay);
  const toggleVideoPlayback = () => {
    setIsPlaying(!isPlaying);
    setButtonIcon(isPlaying ? faCirclePlay : faWindowClose);
  }

  // Function to extract file extension
  const getFileExtension = (filename) => {
    console.log("filename: ", filename)

    return filename ? filename.slice(((filename.lastIndexOf(".") - 1) >>> 0) + 2).toLowerCase() : "";
  };

  // Check if the filename has a valid video extension
  const isValidVideoExtension = ['.mov', '.mp4'].includes(`.${getFileExtension(data.FileName)}`);
  const isVideoType = data && data.MediaType === 1
  
  const limitFloat = (num) => {
    // Ensure the minimum value is 0.001
    num = Math.max(0.001, num);
  
    // Limit to three digits after the decimal point
    num = parseFloat(num.toFixed(3));
  
    return num;
  }
  
  function formatTime(durationInSeconds) {
    const hours = Math.floor(durationInSeconds / 3600);
    const minutes = Math.floor((durationInSeconds % 3600) / 60);
    const seconds = durationInSeconds % 60;

    let formattedTime = '';

    // Add hours component if it's non-zero
    if (hours > 0) {
        formattedTime += `${hours}:`;
    }

    // Add minutes component if it's non-zero or there are hours
    if (minutes >= 10 || hours > 0) {
        formattedTime += `${String(minutes).padStart(2, '0')}:`;
    } else {
      formattedTime += `${String(minutes).padStart(1, '0')}:`;
    }

    // Always add seconds component
    formattedTime += String(seconds).padStart(2, '0');

    return formattedTime;
}

  const providerFullName = GetUserFullname(data.ProviderID, users)
  const team = data.TeamID && teams && teams.find((item) => item.ID === data.TeamID)

  if (data)
  return (
    <>
    <tr>
      {/* <th scope="row"></th> */}
      <td class={data.MarkDelete ? 'text-muted' : ''}>
        {GetDeviceSNWithTag(data.Source, devices)}</td>
      <td class={data.MarkDelete ? 'text-muted' : ''}>
          <div  style={{ display: 'flex', alignItems: 'center' }}>
            {isVideoType && <button class="btn py-0 me-1" 
              onClick={toggleVideoPlayback}><FontAwesomeIcon icon={buttonIcon}/></button>}
            {data.FileName}
          </div>
          <div>
            {isPlaying && isVideoType ? (
              <div style={{ width: '544px', height: '360px' }}>
                <ReactPlayer
                  url={dvSrv + data.Url} // Replace with the video URL from your data
                  width="100%"
                  height="100%"
                  playing={isPlaying}
                  controls muted
                  // Disable download button
                  config={{ file: { attributes: { controlsList: 'nodownload' } } }}
                  // Disable right click
                  onContextMenu={e => e.preventDefault()}                
                />
              </div>
            ) : data.MediaType === 2 ?
              <div style={{ width: '544px', height: '360px' }}><img src={dvSrv + data.Url} width="100%" height="100%" alt="Image File" /></div> : null}
          </div>

      </td>
      <td class={data.MarkDelete ? 'text-muted' : ''}>{limitFloat(data.FileSize/1024/1024)}</td>
      <td class={data.MarkDelete ? 'text-muted' : ''}>{data.Duration !==0 ? formatTime(data.Duration) : ""}</td>
      <td class={data.MarkDelete ? 'text-muted' : ''}>{TimeStringForTimeZone(session, data.CreateDate)}</td>
      {/* <td class={data.MarkDelete ? 'text-muted' : ''}>{data.CaseNumber}</td> */}
      <td class={data.MarkDelete ? 'text-muted' : ''}>
      { providerFullName !== "" ? <span>{providerFullName}</span> : null}
      </td>
      <td class={data.MarkDelete ? 'text-muted' : ''}>{TimeStringForTimeZone(session, data.CreatedAt)}</td>
      <td class={data.MarkDelete ? 'text-muted' : ''}>
        <span>{team ? team.Name : null}</span>
      </td>
      <td>
        <div class="btn-group" role="group">
            {!data.MarkDelete && <button class="btn btn-sm py-0 px-3" id="btn-save-file" onClick={handleSaveSingle}><FontAwesomeIcon icon={faDatabase}/></button>}
            <button class="btn btn-sm py-0" id={data.MarkDelete ? "btn-recycle-file" : "btn-delete-file"} onClick={handleFlipDelete}><FontAwesomeIcon icon={data.MarkDelete ? faRecycle : faTrashCan}/></button>
        </div>
      </td>
    </tr>
    <Tooltip anchorSelect="#btn-delete-file" place="top" arrowColor="#222222">Delete</Tooltip>
    <Tooltip anchorSelect="#btn-recycle-file" place="top" arrowColor="#222222">Recycle</Tooltip>
    <Tooltip anchorSelect="#btn-save-file" place="top" arrowColor="#222222">Save</Tooltip>
    {/* { <ReactJson src={{data}} /> } */}
    </>
  )
}
